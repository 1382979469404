// 代理管理相关接口
import request from "@/request/request";

// 代理商列表
export const AgentList = (params) => request.post(`/agent/admin/agent/agentlist`, params);

// 代理等级列表接口
export const AgentLevel = () => request.post(`/agent/admin/agent/agentlevellist`);

// 根据代理级别编号获取代理列表
export const AgentListbylevelno = (params) => request.post(`/agent/admin/agent/agentListbylevelno`, params);

// 添加代理商
// export const AddAgent = (params) => request.post(`/agent/admin/agent/addagent`, params);

// 编辑代理商
export const EditAgent = (params) => request.post(`/agent/admin/agent/editagent`, params);

// 代理商详情
// export const DetailAgent = (params) => request.post(`/agent/admin/agent/detail`, params);

// 启用/禁用代理
export const Editenableflag = (params) => request.post(`/agent/admin/agent/editenableflag`, params);

// 获取所有会员默认成本
export const AllusermeBercost = () => request.post(`/agent/admin/business/allusermembercost`);

// 获取所有课程默认成本
export const AllcourSecode = (params) => request.post(`/agent/admin/business/allcoursecode`,params);

// 获取所有会员商品默认成本
export const Allusermembercost = () => request.post(`/agent/admin/product/allusermembercost`);

// 获取所有课程商品默认成本
export const PAllcoursecode = (params) => request.post(`/agent/admin/product/allcoursecode`,params);

// 代理商详情
export const VDetailAgent = (params) => request.post(`/agent/admin/agent/v2/detail`, params);

// 添加代理商
export const VAddagent = (params) => request.post(`/agent/admin/agent/v2/addagent`, params);

// 编辑代理商
export const VEditAgent = (params) => request.post(`/agent/admin/agent/v2/editagent`, params);

// 获取小程序码
export const Makeqrcode = (params) => request.post(`/agent/admin/miniprogram/makeqrcode`, params);
