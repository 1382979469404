<template>
    <!-- 菜单管理页面 -->
    <div class="boxEdit" :loading="tableLoading">
        <div class="content">
            <div class="header">
                <div class="aggregate" style="background: #FF8200;" @click="isActiveClass = false">
                    <div class="jbxx" style="color:#fff">1.编辑基本信息</div>
                    <div class="sj1"></div>
                </div>
                <div class="aggregate" @click="aggregateClick" :class="[isActiveClass ? 'activeClass' : 'aggregate']">
                    <div class="sj2"></div>
                    <div class="tjdl">2.添加代理商品</div>
                </div>
            </div>
            <div class="section" v-show="!isActiveClass">
                <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 10 }">
                    <a-form-model-item ref="agentAccount" label="代理账号" prop="agentAccount">
                        <a-input v-model="form.agentAccount" placeholder="请输入手机号码" :disabled="isDisable" style="width:248px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="agentName" label="代理姓名" prop="agentName">
                        <a-input v-model="form.agentName" placeholder="请输入代理姓名" :disabled="isDisable" style="width:248px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="passWord" label="密码" prop="passWord" v-if="this.$route.params.id == 0">
                        <a-input-password v-model="form.passWord" placeholder="请输入密码" style="width:248px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="affirmPassWord" label="确认密码" prop="affirmPassWord" v-if="this.$route.params.id == 0">
                        <a-input-password v-model="form.affirmPassWord" placeholder="请输入确认密码" style="width:248px"/>
                    </a-form-model-item>
                    <!--<a-form-model-item ref="agentName" label="代理渠道码" v-if="this.$route.params.id == 0">
                        <a-input v-model="form.code" placeholder="请输入代理渠道码" :disabled="isDisable" style="width:248px"/>
                    </a-form-model-item>-->
                    <a-form-model-item ref="agentGrade" label="代理等级" prop="agentGrade" v-if="agentLevel == 0">
                        <a-select v-model="form.agentGrade" placeholder="请选择代理等级" @change="agentGradeChange" style="width: 216px" :disabled="isDisable">
                            <a-select-option v-for="item in agentGradeList" :key="item.level+','+item.levelNo">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="belonging" label="所属市级代理" prop="belonging" v-if="( agentLevel == 0 || agentLevel == 1 ) && this.level == 2 && this.$route.params.id == 0">
                        <a-select v-model="form.belonging" placeholder="请选择所属市级代理" style="width: 216px" :disabled="isDisable">
                            <a-select-option v-for="item in municipaLevel" :key="item.agentNo">{{ item.agentName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="appletId" label="代理小程序ID" prop="appletId" v-if="agentLevel == 0 && level == 1">
                        <a-input v-model="form.appletId" placeholder="请输入代理小程序ID" :disabled="isDisable" style="width:248px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="city" label="选择区域" prop="city" v-if="(agentLevel == 0 || agentLevel == 1) && this.$route.params.id == 0">
                        <div style="display: flex;flex-direction: column;">
                            <span style="color:red" v-if="agentLevel == 1">请选择自己代理的区域</span>
                            <span style="color:red" v-if="agentLevel == 0 && level == 2">请选择市级代理对应城市的区域</span>
                            <a-cascader v-if="level != 1" v-model="form.city" :options="options" @change="cityChange" placeholder="请选择区域" style="width: 216px"/>
                            <a-cascader v-else v-model="form.city" :options="cityOptions" @change="cityChange" placeholder="请选择区域" style="width: 216px"/>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item ref="bond" label="保证金" prop="bond" v-if="agentLevel == 0">
                        <a-input-number v-model="form.bond" :min="0" placeholder="请输入保证金" style="width: 216px"/>
                    </a-form-model-item>
                    <!-- <a-form-model-item ref="vipNum" label="预购会员卡数量" prop="vipNum">
                        <a-input-number v-model="form.vipNum" placeholder="请输入预购会员卡数量" />
                    </a-form-model-item> -->
                    <a-form-model-item ref="shareProportion" label="分成比例" prop="shareProportion" v-if="agentLevel != 2">
                        <a-input-number v-model="form.shareProportion" :min="0" placeholder="请输入分成比例" style="width: 216px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="regionalDivision" label="地域归属分成比例" prop="regionalDivision" v-if="agentLevel == 0 && this.level == 1">
                        <a-input-number v-model="form.regionalDivision" :min="0" placeholder="请输入地域归属分成比例" style="width: 216px"/>
                    </a-form-model-item>
                    <a-form-model-item ref="termOfValidity" label="有效期" prop="termOfValidity">
                        <a-range-picker :value="form.termOfValidity" format="YYYY-MM-DD" @change="termChange" style="width:248px"/>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="csection" v-show="isActiveClass">
                <div class="name"> <span>*</span> 代理商品：</div>
                <div class="searchStyle">
                    <div class="tab">
                        <div class="tabName" :class="{ tabStyle : tabChange == index }" v-for="(item,index) in tabList" :key="index" @click="tabClick(index)">{{item.name}}</div>
                    </div>
                    <div class="seach" v-if="tabChange == 0">
                        <a-input-search v-model="culumval" placeholder="请输入课程名称" enter-button @search="searchValue" style="width:240px">
                            <a-icon slot="prefix" type="search" />
                        </a-input-search>
                    </div>
                </div>

                <div class="tabel">
                    <!-- 课程 -->
                    <div v-show="tabChange == 0" >
                        <a-table v-if="agentLevel == 0" :dataSource="curriculumDataSource" :columns="curriculumColumns" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.courseNo" ref="courseTable" :row-selection="{ selectedRowKeys: curriculumRowKeys, onChange: onCurriculumChange, onSelect:onSelectChange, onSelectAll:onSelectAllChange}">
                            <span slot="putawayFlag" slot-scope="putawayFlag, record">
                                <a-badge v-if="putawayFlag == 0" color="#999999" />
                                <a-badge v-else-if="putawayFlag == 1" color="#FF8200" />
                                <a-badge v-else-if="putawayFlag == 2" color="#67C23A" />
                                <a-badge v-else-if="putawayFlag == 3" color="#108ee9" />
                                <span>{{ record.putawayFlag == 0 ? '已下架' : record.putawayFlag == 1 ? '定时上架' : record.putawayFlag == 2 ? '已上架' : record.putawayFlag == 3 ? '暂时上架' : '--' }}</span>
                            </span>
                            <span slot="cost" slot-scope="cost, record">
                                <a-input v-if="$route.params.id == 0" v-model="record.cost" placeholder="请输入成本" style="width:120px"/>
                                <a-input v-else v-model="record.cost" :disabled="!record.isTrue" placeholder="请输入成本" style="width:120px" @change="SelectChange(record)"/>
                            </span>
                        </a-table>
                        <a-table v-else-if="agentLevel == 1 || agentLevel == 2" :dataSource="curriculumDataSource" :columns="curriculumColumns0" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.courseNo" ref="courseTable" :row-selection="{ selectedRowKeys: curriculumRowKeys, onChange: onCurriculumChange, onSelect:onSelectChange, onSelectAll:onSelectAllChange}"></a-table>
                    </div>

                    <!-- 会员 -->
                    <div v-show="tabChange == 1">
                        <a-table v-if="agentLevel == 0" :dataSource="vipDataSource" :columns="vipColumns" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.memberNo" :row-selection="{ selectedRowKeys: vipRowKeys, onChange: onVipChange, onSelect:onSelectVipChange, onSelectAll:onSelectVipAllChange }">
                            <span slot="forbiddenStatus" slot-scope="forbiddenStatus, record">
                                <a-badge v-if="forbiddenStatus == 1" color="#67C23A" />
                                <a-badge v-else-if="forbiddenStatus == 2" color="#999999" />
                                <span>{{ record.forbiddenStatus == 1 ? '使用中' : record.forbiddenStatus == 2 ? '已停止' : '--' }}</span>
                            </span>
                            <span slot="cost" slot-scope="cost, record">
                                <a-input v-if="$route.params.id == 0" v-model="record.cost" placeholder="请输入成本" style="width:120px" />
                                <a-input v-else v-model="record.cost" :disabled="!record.isTrue" placeholder="请输入成本" style="width:120px" @change="SelectVipChange(record)"/>
                            </span>
                        </a-table>
                        <a-table v-else-if="agentLevel == 1 || agentLevel == 2" :dataSource="vipDataSource" :columns="vipColumns0" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.memberNo" :row-selection="{ selectedRowKeys: vipRowKeys, onChange: onVipChange, onSelect:onSelectVipChange, onSelectAll:onSelectVipAllChange }"></a-table>
                    </div>

                    <!-- 直销课程 -->
                    <div v-show="tabChange == 2" >
                        <a-table v-if="agentLevel == 0" :dataSource="sellingCourseData" :columns="sellingCourseColumns" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.productId" ref="courseTable" :row-selection="{ selectedRowKeys: sellingCourseRowKeys, onChange: onSellingCurriculumChange, onSelect:onSellingSelectChange, onSelectAll:onSellingSelectAllChange}">
                            <span slot="enableFlag" slot-scope="enableFlag, record">
                                <a-badge v-if="enableFlag == 0" color="#999999" />
                                <a-badge v-else-if="enableFlag == 1" color="#67C23A" />
                                <span>{{ record.enableFlag == 0 ? '否' : record.enableFlag == 1 ? '是' : '--' }}</span>
                            </span>
                            <span slot="productType" slot-scope="productType, record">
                                <span>{{ record.productType == 2 ? '会员' : record.productType == 1 ? '课程' : '--' }}</span>
                            </span>
                            <span slot="cost" slot-scope="cost, record">
                                <a-input v-if="$route.params.id == 0" v-model="record.cost" placeholder="请输入成本" style="width:120px"/>
                                <a-input v-else v-model="record.cost" :disabled="!record.isTrue" placeholder="请输入成本" style="width:120px" @change="sellingSelectChange(record)"/>
                            </span>
                        </a-table>
                        <a-table v-else-if="agentLevel == 1 || agentLevel == 2" :dataSource="sellingCourseData" :columns="sellingCourseColumns0" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.productId" ref="courseTable" :row-selection="{ selectedRowKeys: sellingCourseRowKeys, onChange: onSellingCurriculumChange, onSelect:onSellingSelectChange, onSelectAll:onSellingSelectAllChange}"></a-table>
                    </div>

                    <!-- 直销会员 -->
                    <div v-show="tabChange == 3" >
                        <a-table v-if="agentLevel == 0" :dataSource="sellingVipData" :columns="sellingVipColumns" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.productId" ref="courseTable" :row-selection="{ selectedRowKeys: sellingVipRowKeys, onChange: onSellingVipCurriculumChange, onSelect:onSellingVipSelectChange, onSelectAll:onSellingVipSelectAllChange}">
                            <span slot="enableFlag" slot-scope="enableFlag, record">
                                <a-badge v-if="enableFlag == 0" color="#999999" />
                                <a-badge v-else-if="enableFlag == 1" color="#67C23A" />
                                <span>{{ record.enableFlag == 0 ? '否' : record.enableFlag == 1 ? '是' : '--' }}</span>
                            </span>
                            <span slot="productType" slot-scope="productType, record">
                                <span>{{ record.productType == 2 ? '会员' : record.productType == 1 ? '课程' : '--' }}</span>
                            </span>
                            <span slot="cost" slot-scope="cost, record">
                                <a-input v-if="$route.params.id == 0" v-model="record.cost" placeholder="请输入成本" style="width:120px"/>
                                <a-input v-else v-model="record.cost" :disabled="!record.isTrue" placeholder="请输入成本" style="width:120px" @change="sellingSelectVipChange(record)"/>
                            </span>
                        </a-table>
                        <a-table v-else-if="agentLevel == 1 || agentLevel == 2" :dataSource="sellingVipData" :columns="sellingVipColumns0" :pagination="false" bordered :loading="tableLoading" :row-key="record => record.productId" ref="courseTable" :row-selection="{ selectedRowKeys: sellingVipRowKeys, onChange: onSellingVipCurriculumChange, onSelect:onSellingVipSelectChange, onSelectAll:onSellingVipSelectAllChange}"></a-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn">
            <footer-tool-bar :collapsed="sideCollapsed">
                <a-button @click="()=>$router.go(-1)" class="margin_right10">取消</a-button>
                <a-button v-if="$route.params.id == 0" type="primary" @click="onSubmit">确定</a-button>
                <a-button v-else type="primary" @click="onSubmit(),editArrInitVip(editVipListArr, 'memberNo'),editArrInit(editListArr, 'courseNo'),sellingEditArrInit(sellingEditListArr,'productId'),sellingEditArrInitVip(sellingEditVipListArr,'productId')">确定</a-button>
            </footer-tool-bar>
        </div>
        <router-view />
    </div>
</template>

<script>
import moment from "moment";
import province from "@/utils/province.json";
import city from "@/utils/city.json";
import FooterToolBar from '@/components/FooterToolbar';
import { Modal, message } from "ant-design-vue";
import { baseMixin } from '@/store/app-mixin';
import {
    AgentLevel,
    AllusermeBercost,
    AllcourSecode,
    AgentListbylevelno,
    PAllcoursecode,
    Allusermembercost,
    VAddagent,
    VDetailAgent,
    VEditAgent
} from "@/request/api/agenManage";
export default {
    mixins: [baseMixin],
    components: { FooterToolBar },
    created () {
        this.agentLevel = JSON.parse(localStorage.getItem("user")).agentLevel // 0:超级管理员 1:市级代理 2:区域代理 3:推广大使
        let id = this.$route.params.id
        this.vipListFn()
        this.curriculumListFn()
        this.sellingCourseFn()
        this.sellingVipFn()
        this.agentGrade()
        if (id != 0) {
            this.detailsInit()
            this.isDisable = true
        } else {
            setTimeout(() => {
                this.forInit()
            },1000)
        }
        this.options = province.address
        this.cityOptions = city.address
    },
    data () {
        return {
            culumval:'',
            agentLevel:'',
            isDisable:false,
            tableLoading:true,
            isActiveClass: false, //切换顶部tab
            tabChange: 0, //切换会员与课程
            dateFormat: 'YYYY-MM-DD',
            // 省市区字段
            options: [],
            cityOptions: [],
            // 基本信息相关
            form: {
                agentAccount: '',
                agentName: '',
                code:'',
                agentGrade: undefined,
                belonging: undefined,
                region: undefined,
                bond: '',
                appletId:'',
                city: undefined,
                vipNum: '',
                shareProportion: '',
                regionalDivision: '',
                termOfValidity: [],
                passWord:'',
                affirmPassWord:'',
            },
            rules: {
                agentAccount: [
                    { required: true, message: '请输入代理账号', trigger: 'blur' },
                ],
                agentName: [
                    { required: true, message: '请输入代理姓名', trigger: 'blur' },
                ],
                agentGrade: [
                    { required: true, message: '请选择代理等级', trigger: 'change' },
                ],
                belonging: [
                    { required: true, message: '请选择市级代理', trigger: 'change' },
                ],
                region: [
                    { required: true, message: '请选择区域', trigger: 'change' },
                ],
                bond: [
                    { required: true, message: '请输入保证金', trigger: 'blur' },
                ],
                appletId: [
                    { required: true, message: '请输入小程序ID', trigger: 'blur' },
                ],
                city: [
                    { required: true, message: '请选择市', trigger: 'change' },
                ],
                vipNum: [
                    { required: true, message: '请输入预购会员卡数量', trigger: 'blur' },
                ],
                shareProportion: [
                    { required: true, message: '请输入分成比例', trigger: 'blur' },
                ],
                regionalDivision: [
                    { required: true, message: '请输入地域归属分成比例', trigger: 'blur' },
                ],
                termOfValidity: [
                    { required: true, message: '请输入有效期', trigger: 'blur' },
                ],
                passWord:[
                    { required: true, validator: this.validatePwd, trigger: 'blur' },
                ],
                affirmPassWord:[
                    { required: true, validator: this.validateConfirmPwd, trigger: "blur" },
                ],
            },
            tabList: [
                {
                    id: 1,
                    name: '课程'
                }, {
                    id: 2,
                    name: '会员'
                },{
                    id: 3,
                    name:'直销课程',
                },{
                    id: 4,
                    name:'直销会员',
                }
            ],

            // 课程表格相关
            curriculumRowKeys: [],
            curriculumColumns: [
                {
                    title: '课程名称',
                    dataIndex: 'courseName',
                    key: 'courseName',
                },
                {
                    title: '状态',
                    dataIndex: 'putawayFlag',
                    key: 'putawayFlag',
                    scopedSlots: { customRender: "putawayFlag" },
                },
                {
                    title: '课程类型',
                    dataIndex: 'courseType',
                    key: 'courseType',
                },
                {
                    title: '定价',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '成本',
                    dataIndex: 'cost',
                    key: 'cost',
                    scopedSlots: { customRender: "cost" },
                },
            ],
            curriculumColumns0: [
                {
                    title: '课程名称',
                    dataIndex: 'courseName',
                    key: 'courseName',
                },
                {
                    title: '定价',
                    dataIndex: 'price',
                    key: 'price',
                },
            ],
            curriculumDataSource: [],

            // 会员表格相关
            vipRowKeys: [],
            vipColumns: [
                {
                    title: '会员名称',
                    dataIndex: 'memberName',
                    key: 'memberName',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '状态',
                    dataIndex: 'forbiddenStatus',
                    key: 'forbiddenStatus',
                    scopedSlots: { customRender: "forbiddenStatus" },
                },
                {
                    title: '有效期',
                    dataIndex: 'days',
                    key: 'days',
                },
                {
                    title: '成本',
                    dataIndex: 'cost',
                    key: 'cost',
                    scopedSlots: { customRender: "cost" },
                },
            ],
            vipColumns0: [
                {
                    title: '会员名称',
                    dataIndex: 'memberName',
                    key: 'memberName',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '有效期',
                    dataIndex: 'days',
                    key: 'days',
                },
            ],
            vipDataSource: [],

            // 直销课程
            sellingCourseRowKeys:[],
            sellingCourseColumns:[
                {
                    title: '商品名称',
                    dataIndex: 'productName',
                    key: 'productName',
                },
                {
                    title: '是否可用',
                    dataIndex: 'enableFlag',
                    key: 'enableFlag',
                    scopedSlots: { customRender: "enableFlag" },
                },
                {
                    title: '课程类型',
                    dataIndex: 'productType',
                    key: 'productType',
                    scopedSlots: { customRender: "productType" },
                },
                {
                    title: '定价',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '成本',
                    dataIndex: 'cost',
                    key: 'cost',
                    scopedSlots: { customRender: "cost" },
                },
            ],
            sellingCourseColumns0:[
                {
                    title: '商品名称',
                    dataIndex: 'productName',
                    key: 'productName',
                },
                {
                    title: '定价',
                    dataIndex: 'price',
                    key: 'price',
                },
            ],
            sellingCourseData: [],

            // 直销会员
            sellingVipRowKeys:[],
            sellingVipColumns:[
                {
                    title: '商品名称',
                    dataIndex: 'productName',
                    key: 'productName',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '状态',
                    dataIndex: 'enableFlag',
                    key: 'enableFlag',
                    scopedSlots: { customRender: "enableFlag" },
                },
                {
                    title: '商品类型',
                    dataIndex: 'productType',
                    key: 'productType',
                    scopedSlots: { customRender: "productType" },
                },
                {
                    title: '成本',
                    dataIndex: 'cost',
                    key: 'cost',
                    scopedSlots: { customRender: "cost" },
                },
            ],
            sellingVipColumns0:[
                {
                    title: '商品名称',
                    dataIndex: 'productName',
                    key: 'productName',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                    key: 'price',
                },
            ],
            sellingVipData: [],

            // 代理等级数组
            agentGradeList: [],
            // 所属市级代理
            municipaLevel: [],

            // 用到的参数，作为判断
            level: '',
            levelNo: '',

            // 开始时间、结束时间
            starTime: '',
            endTime: '',

            // 省市区字段
            province: '',
            city: '',
            area: '',

            // 用于存储课程/vip临时数据
            arrays: [], // 新增时整体提交
            sellingArrays:[], // 新增时提交直销数据

            editListArr: [], //编辑时，课程 有变动的数据集合
            editVipListArr: [], //编辑时，vip 有变动的数据集合
            sellingEditListArr:[],
            sellingEditVipListArr:[],
            arrt: [], // 课程 去重过滤后的数组
            arrtVip: [], //VIP 去重过滤后的数组

            jsonCurriculumRowKeys: '',
            jsonvipRowKeys:'',
            jsonsellingCurriculumRowKeysArrJson:'',
            jsonsellingvipRowKeysArrJson:'',

            result: [],
            vipResult:[],
            sellingresult: [],
            sellingvipResult:[],
        };
    },
    methods: {
        moment,
        // 密码校验规则
        validatePwd(rule, value, callback) {
            let reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[0-9a-zA-Z]{8,16}$/;
            if (value === "") {
                callback("输入的密码不能为空");
            } else if (!reg.test(value)) {
                callback(
                    "请输入8-16位字母+数字组合，必须含有大小写字母"
                );
            } else {
                callback();
            }
        },

        // 确认密码校验规则
        validateConfirmPwd(rule, value, callback) {
            if (value == "") {
                callback("输入的确认密码不能为空");
            } else if (value != this.form.passWord) {
                callback("和上面密码不一致, 请重新输入");
            } else {
                callback();
            }
        },

        // 课程表格搜索
        searchValue () {
            this.curriculumDataSource = []
            let id = this.$route.params.id
            if (id == 0) {
                this.curriculumListFn()
            } else {
                this.curriculumListFn()
                setTimeout(() => {
                    this.detailsInit()
                },500)
            }
        },

        // 上方标签页切换
        aggregateClick () {
            this.isActiveClass = true
        },

        // 改变vip成本
        SelectVipChange (record) {
            this.editVipListArr.push(record)
        },

        // 改变课程成本
        SelectChange (record) {
            this.editListArr.push(record)
        },

        // 改变直销课程成本
        sellingSelectChange(record){
            this.sellingEditListArr.push(record)
        },

        // 改变直销vip成本
        sellingSelectVipChange(record){
            this.sellingEditVipListArr.push(record)
        },

        //-----------------------------------------------------------------------------------------------------

        // 循环课程数组
        curriculumData () {
            for (let i = 0; i < this.curriculumDataSource.length;i++){
                if (this.curriculumDataSource[i].isTrue) {
                    let obj = {
                        businessNo: this.curriculumDataSource[i].courseNo,
                        businessType: 1,
                        cost: this.curriculumDataSource[i].cost === '' ? '' : this.curriculumDataSource[i].cost * 1,
                        enableFlag: 1,
                    }
                    this.arrays.push(obj)
                }else {
                    let obj = {
                        businessNo: this.curriculumDataSource[i].courseNo,
                        businessType: 1,
                        cost: this.curriculumDataSource[i].cost === '' ? '' : this.curriculumDataSource[i].cost * 1,
                        enableFlag: 0,
                    }
                    this.arrays.push(obj)
                }
            }
        },

        // 循环vip课程数组
        vipCurriculumData () {
            for (let i = 0; i < this.vipDataSource.length;i++){
                if (this.vipDataSource[i].isTrue) {
                    let obj = {
                        businessNo: this.vipDataSource[i].memberNo,
                        businessType:2,
                        cost:this.vipDataSource[i].cost === '' ? '' : this.vipDataSource[i].cost * 1,
                        enableFlag:1,
                    }
                    this.arrays.push(obj)
                }else{
                    let obj = {
                        businessNo: this.vipDataSource[i].memberNo,
                        businessType:2,
                        cost:this.vipDataSource[i].cost === '' ? '' : this.vipDataSource[i].cost * 1,
                        enableFlag:0,
                    }
                    this.arrays.push(obj)
                }
            }
        },

        // 循环直销课程数组
        sellingCurriculumData () {
            for (let i = 0; i < this.sellingCourseData.length;i++){
                if (this.sellingCourseData[i].isTrue) {
                    let obj = {
                        productId: this.sellingCourseData[i].productId,
                        productType: 1,
                        cost: this.sellingCourseData[i].cost === '' ? '' : this.sellingCourseData[i].cost * 1,
                        enableFlag: 1,
                    }
                    this.sellingArrays.push(obj)
                }else {
                    let obj = {
                        productId: this.sellingCourseData[i].productId,
                        productType: 1,
                        cost: this.sellingCourseData[i].cost === '' ? '' : this.sellingCourseData[i].cost * 1,
                        enableFlag: 0,
                    }
                    this.sellingArrays.push(obj)
                }
            }
        },

        // 循环直销会员数组
        sellingAllVipData () {
            for (let i = 0; i < this.sellingVipData.length;i++){
                if (this.sellingVipData[i].isTrue) {
                    let obj = {
                        productId: this.sellingVipData[i].productId,
                        productType: 2,
                        cost: this.sellingVipData[i].cost === '' ? '' : this.sellingVipData[i].cost * 1,
                        enableFlag: 1,
                    }
                    this.sellingArrays.push(obj)
                }else {
                    let obj = {
                        productId: this.sellingVipData[i].productId,
                        productType: 2,
                        cost: this.sellingVipData[i].cost === '' ? '' : this.sellingVipData[i].cost * 1,
                        enableFlag: 0,
                    }
                    this.sellingArrays.push(obj)
                }
            }
        },

        //-------------------------------------------------------------------------------------------------------

        // 课程 改变的数组去重 保留最后添加的
        editArrInit (arr, name) {
            let hash = {};
            let arrt = []
            arr.reduce(function (acc, cru, index) {
                if (!hash[cru[name]]) {
                    hash[cru[name]]={index:arrt.length}
                    arrt.push(cru)
                }else{
                    arrt.splice(hash[cru[name]]['index'],1,cru)
                }
            }, []);
            this.editArrInits(arrt)
        },
        editArrInits (arr) {
            let jsonKeys = JSON.parse(this.jsonCurriculumRowKeys)
            this.result = [];
            for (let i = 0; i < arr.length; i++){
                let obj = {
                    businessNo: arr[i].courseNo,
                    businessType:1,
                    cost:arr[i].cost === '' ? '' : arr[i].cost * 1,
                    enableFlag: arr[i].isTrue ? 1 : 0,
                }
                let objId =  arr[i].courseNo;
                let objIsTrue = arr[i].isTrue
                let cost = arr[i].cost
                let isExist = false;
                for (let j = 0; j < jsonKeys.length; j++){
                    let aj = jsonKeys[j].courseNo;
                    let n = aj;
                    let ajTrue = jsonKeys[j].isTrue;
                    let costNum = jsonKeys[j].cost
                    if (n == objId && objIsTrue == ajTrue && cost === costNum) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    this.result.push(obj)
                }
            }
        },

        //---------------------------------------------------------------------------------------------------------

        // vip改变的数组去重 保留最后添加的
        editArrInitVip (arr, name) {
            let hash = {};
            this.arrtVip = []
            let arrt = []
            arr.reduce(function (acc, cru, index) {
                if (!hash[cru[name]]) {
                    hash[cru[name]]={index:arrt.length}
                    arrt.push(cru)
                }else{
                    arrt.splice(hash[cru[name]]['index'],1,cru)
                }
            }, []);
            this.editArrInitVips(arrt)
        },
        editArrInitVips (arr) {
            let jsonKeys = JSON.parse(this.jsonvipRowKeys)
            this.vipResult = [];
            for (let i = 0; i < arr.length; i++){
                let obj = {
                    businessNo: arr[i].memberNo,
                    businessType:2,
                    cost:arr[i].cost === '' ? '' : arr[i].cost * 1,
                    enableFlag: arr[i].isTrue ? 1 : 0,
                }
                let cost = arr[i].cost
                let objId = arr[i].memberNo;
                let objIsTrue = arr[i].isTrue
                let isExist = false;
                for (let j = 0; j < jsonKeys.length; j++){
                    let aj = jsonKeys[j].memberNo;
                    let n = aj;
                    let ajTrue = jsonKeys[j].isTrue;
                    let costNum = jsonKeys[j].cost
                    if (n == objId && objIsTrue == ajTrue && cost === costNum) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    this.vipResult.push(obj)
                }
            }
        },

        //---------------------------------------------------------------------------------------------------------

        // 直销 - 课程 改变的数组去重 保留最后添加的
        sellingEditArrInit (arr, name) {
            let hash = {};
            let arrt = []
            arr.reduce(function (acc, cru, index) {
                if (!hash[cru[name]]) {
                    hash[cru[name]]={index:arrt.length}
                    arrt.push(cru)
                }else{
                    arrt.splice(hash[cru[name]]['index'],1,cru)
                }
            }, []);
            this.sellingEditArrInits(arrt)
        },
        sellingEditArrInits (arr) {
            let jsonKeys = JSON.parse(this.jsonsellingCurriculumRowKeysArrJson)
            this.sellingresult = [];
            for (let i = 0; i < arr.length; i++){
                let obj = {
                    productId: arr[i].productId,
                    productType:1,
                    cost:arr[i].cost === '' ? '' : arr[i].cost * 1,
                    enableFlag: arr[i].isTrue ? 1 : 0,
                }
                let objId =  arr[i].productId;
                let objIsTrue = arr[i].isTrue
                let cost = arr[i].cost
                let isExist = false;
                for (let j = 0; j < jsonKeys.length; j++){
                    let aj = jsonKeys[j].productId;
                    let n = aj;
                    let ajTrue = jsonKeys[j].isTrue;
                    let costNum = jsonKeys[j].cost
                    if (n == objId && objIsTrue == ajTrue && cost === costNum) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    this.sellingresult.push(obj)
                }
            }
        },

        //---------------------------------------------------------------------------------------------------------

        // 直销 - vip 改变的数组去重 保留最后添加的
        sellingEditArrInitVip (arr, name) {
            let hash = {};
            this.arrtVip = []
            let arrt = []
            arr.reduce(function (acc, cru, index) {
                if (!hash[cru[name]]) {
                    hash[cru[name]]={index:arrt.length}
                    arrt.push(cru)
                }else{
                    arrt.splice(hash[cru[name]]['index'],1,cru)
                }
            }, []);
            this.sellingEditArrInitVips(arrt)
        },
        sellingEditArrInitVips (arr) {
            let jsonKeys = JSON.parse(this.jsonsellingvipRowKeysArrJson)
            this.sellingvipResult = [];
            for (let i = 0; i < arr.length; i++){
                let obj = {
                    productId: arr[i].productId,
                    productType:2,
                    cost:arr[i].cost === '' ? '' : arr[i].cost * 1,
                    enableFlag: arr[i].isTrue ? 1 : 0,
                }
                let cost = arr[i].cost
                let objId = arr[i].productId;
                let objIsTrue = arr[i].isTrue
                let isExist = false;
                for (let j = 0; j < jsonKeys.length; j++){
                    let aj = jsonKeys[j].productId;
                    let n = aj;
                    let ajTrue = jsonKeys[j].isTrue;
                    let costNum = jsonKeys[j].cost
                    if (n == objId && objIsTrue == ajTrue && cost === costNum) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    this.sellingvipResult.push(obj)
                }
            }
        },

        //---------------------------------------------------------------------------------------------------------

        // 点击提交按钮
        onSubmit () {
            this.arrays = []
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let id = this.$route.params.id
                    if (id == 0) {
                        this.curriculumData()
                        this.vipCurriculumData()
                        this.sellingCurriculumData()
                        this.sellingAllVipData()
                        if (this.agentLevel == 0) {
                            for (let i = 0; i < this.arrays.length; i++){
                                if (this.arrays[i].cost === '' && this.arrays[i].enableFlag == 1) {
                                    message.error('成本不能为空')
                                    return false
                                }
                            }
                            for (let i = 0; i < this.sellingArrays.length; i++){
                                if (this.sellingArrays[i].cost === '' && this.sellingArrays[i].enableFlag == 1) {
                                    message.error('成本不能为空')
                                    return false
                                }
                            }
                        }
                        this.tableLoading = true;
                        let obj = {
                            agentPass:this.form.passWord, //密码
                            phone: this.form.agentAccount, //代理账号
                            name: this.form.agentName, //代理姓名
                            code: this.form.code, //代理标志
                            appId: this.form.appletId, //代理的小程序ID
                            levelNo: this.levelNo, //代理等级编号
                            parentNo: this.form.belonging == undefined ? '' : this.form.belonging, //父级代理编号
                            city: this.city, //代理区域-市
                            county: this.level == 1 ? '' : this.area, //代理区域-区县
                            earnesMoney: !this.form.bond ? 0 : this.form.bond, //保证金
                            divisionProportion: !this.form.shareProportion ? 0 : this.form.shareProportion, //分成比例
                            areaDivisionProportion: this.form.regionalDivision == undefined || !this.form.regionalDivision ? 0 : this.form.regionalDivision, //地域分成比例
                            startTime: this.starTime, //开始时间
                            endTime: this.endTime, //结束时间
                            businessList:this.arrays, // 数组
                            productList:this.sellingArrays, // 数组
                        }
                        VAddagent(obj).then(({ code, data }) => {
                            if (code === 200) {
                                this.tableLoading = false;
                                this.$router.push(`/agenManage/upertubeManage`);
                            } else {
                                this.tableLoading = false;
                            }
                        });
                    } else {
                        let self = this
                        Modal.confirm({
                            title: "提示",
                            content: "确定要保存课程配置吗？",
                            okText: "确认",
                            okType: "danger",
                            cancelText: "取消",
                            onOk () {
                                let array = self.vipResult.concat(self.result)
                                let sellingArray = self.sellingvipResult.concat(self.sellingresult)
                                if (self.agentLevel == 0) {
                                    for (let i = 0; i < array.length; i++) {
                                        if (array[i].cost === '' && array[i].enableFlag == 1) {
                                            message.error('成本不能为空')
                                            return false
                                        }
                                    }
                                    for (let i = 0; i < sellingArray.length; i++) {
                                        if (sellingArray[i].cost === '' && sellingArray[i].enableFlag == 1) {
                                            message.error('成本不能为空')
                                            return false
                                        }
                                    }
                                }
                                self.tableLoading = true;
                                let obj = {
                                    agentNo: id,
                                    phone: self.form.agentAccount, //代理账号
                                    code: self.form.code, //代理标志
                                    earnesMoney: self.form.bond, //保证金
                                    divisionProportion: self.form.shareProportion, //分成比例
                                    vareaDivisionProportion: self.form.regionalDivision == undefined ? '' : self.form.regionalDivision, //地域分成比例
                                    startTime: self.starTime, //开始时间
                                    endTime: self.endTime, //结束时间
                                    businessList: array, // 数组
                                    productList: sellingArray, // 数组
                                }
                                VEditAgent(obj).then(({ code, data }) => {
                                    if (code === 200) {
                                        self.tableLoading = false;
                                        self.$router.push(`/agenManage/upertubeManage`);
                                    } else {
                                        self.tableLoading = false
                                    }
                                });
                            },
                        });
                    }
                } else {
                    this.isActiveClass = false
                    return false;
                }
            });
        },

        // 点击切换标签页
        tabClick (index) {
            this.tabChange = index
        },

        // 有效期监听
        termChange (val,dateString) {
            this.starTime = dateString[0] + ' ' + '00:00:00';
            this.endTime = dateString[1] + ' ' + '00:00:00';
            this.form.termOfValidity = [
                moment(dateString[0], this.dateFormat),
                moment(dateString[1], this.dateFormat)
            ]
        },

        //----------------------------------------------------------------------------------------------------

        // 课程表格change事件
        onCurriculumChange (selectedRowKeys) {
            this.curriculumRowKeys = selectedRowKeys
        },

        // 监听 课程 数据选中/取消选中事件
        onSelectChange (selected) {
            let id = this.$route.params.id
            if (id == 0) {
                selected.isTrue = !selected.isTrue
            } else {
                if (selected.isTrue == undefined) {
                    selected.isTrue = true
                } else {
                    selected.isTrue = !selected.isTrue
                }
                this.editListArr.push(selected)
            }
        },

        // 监听 课程 数据全部选中/取消选中事件
        onSelectAllChange (selected) {
            let id = this.$route.params.id
            if (id == 0) {
                if (selected) {
                    for (let i = 0; i < this.curriculumDataSource.length; i++){
                        this.curriculumDataSource[i].isTrue = true
                    }
                } else {
                    for (let i = 0; i < this.curriculumDataSource.length; i++){
                        this.curriculumDataSource[i].isTrue = false
                    }
                }
            } else {
                if (selected) {
                    for (let i = 0; i < this.curriculumDataSource.length; i++){
                        this.curriculumDataSource[i].isTrue = true
                        this.editListArr.push(this.curriculumDataSource[i])
                    }
                } else {
                    for (let i = 0; i < this.curriculumDataSource.length; i++){
                        this.curriculumDataSource[i].isTrue = false
                        this.editListArr.push(this.curriculumDataSource[i])
                    }
                }
            }
        },

        //--------------------------------------------------------------------------------------------------------

        // 会员表格change事件
        onVipChange (selectedRowKeys) {
            this.vipRowKeys = selectedRowKeys
        },

        // 监听 vip 数据选中/取消选中事件
        onSelectVipChange (selected) {
            let id = this.$route.params.id
            if (id == 0) {
                selected.isTrue = !selected.isTrue
            } else {
                if (selected.isTrue == undefined) {
                    selected.isTrue = true
                } else {
                    selected.isTrue = !selected.isTrue
                }
                this.editVipListArr.push(selected)
            }
        },

        // 监听 vip 数据选中/取消选中事件
        onSelectVipAllChange (selected) {
            let id = this.$route.params.id
            if (id == 0) {
                if (selected) {
                    for (let i = 0; i < this.vipDataSource.length; i++){
                        this.vipDataSource[i].isTrue = true
                    }
                } else {
                    for (let i = 0; i < this.vipDataSource.length; i++){
                        this.vipDataSource[i].isTrue = false
                    }
                }
            } else {
                if (selected) {
                    for (let i = 0; i < this.vipDataSource.length; i++){
                        this.vipDataSource[i].isTrue = true
                        this.editVipListArr.push(this.vipDataSource[i])
                    }
                } else {
                    for (let i = 0; i < this.vipDataSource.length; i++){
                        this.vipDataSource[i].isTrue = false
                        this.editVipListArr.push(this.vipDataSource[i])
                    }
                }
            }
        },

        //----------------------------------------------------------------------------------------------------

        // 直销课程表格change事件
        onSellingCurriculumChange(selectedRowKeys){
            this.sellingCourseRowKeys = selectedRowKeys
        },

        // 监听直销课程数据选中/取消选中事件
        onSellingSelectChange(selected){
            let id = this.$route.params.id
            if (id == 0) {
                selected.isTrue = !selected.isTrue
            } else {
                if (selected.isTrue == undefined) {
                    selected.isTrue = true
                } else {
                    selected.isTrue = !selected.isTrue
                }
                this.sellingEditListArr.push(selected)
            }

            console.log(this.sellingEditListArr)
        },

        // 监听 直销课程 数据选中/取消选中事件
        onSellingSelectAllChange(selected){
            let id = this.$route.params.id
            if (id == 0) {
                if (selected) {
                    for (let i = 0; i < this.sellingCourseData.length; i++){
                        this.sellingCourseData[i].isTrue = true
                    }
                } else {
                    for (let i = 0; i < this.sellingCourseData.length; i++){
                        this.sellingCourseData[i].isTrue = false
                    }
                }
            } else {
                if (selected) {
                    for (let i = 0; i < this.sellingCourseData.length; i++){
                        this.sellingCourseData[i].isTrue = true
                        this.sellingEditListArr.push(this.sellingCourseData[i])
                    }
                } else {
                    for (let i = 0; i < this.sellingCourseData.length; i++){
                        this.sellingCourseData[i].isTrue = false
                        this.sellingEditListArr.push(this.sellingCourseData[i])
                    }
                }
            }
            console.log(this.sellingEditListArr)
        },

        //------------------------------------------------------------------------------------------------------

        // 直销vip表格change事件
        onSellingVipCurriculumChange(selectedRowKeys){
            this.sellingVipRowKeys = selectedRowKeys
        },

        // 监听直销vip数据选中/取消选中事件
        onSellingVipSelectChange(selected){
            let id = this.$route.params.id
            if (id == 0) {
                selected.isTrue = !selected.isTrue
            } else {
                if (selected.isTrue == undefined) {
                    selected.isTrue = true
                } else {
                    selected.isTrue = !selected.isTrue
                }
                this.sellingEditVipListArr.push(selected)
            }
            console.log(this.sellingEditVipListArr)
        },

        // 监听 直销vip 数据选中/取消选中事件
        onSellingVipSelectAllChange(selected){
            let id = this.$route.params.id
            if (id == 0) {
                if (selected) {
                    for (let i = 0; i < this.sellingVipData.length; i++){
                        this.sellingVipData[i].isTrue = true
                    }
                } else {
                    for (let i = 0; i < this.sellingVipData.length; i++){
                        this.sellingVipData[i].isTrue = false
                    }
                }
            } else {
                if (selected) {
                    for (let i = 0; i < this.sellingVipData.length; i++){
                        this.sellingVipData[i].isTrue = true
                        this.sellingEditVipListArr.push(this.sellingVipData[i])
                    }
                } else {
                    for (let i = 0; i < this.sellingVipData.length; i++){
                        this.sellingVipData[i].isTrue = false
                        this.sellingEditVipListArr.push(this.sellingVipData[i])
                    }
                }
            }
            console.log(this.sellingEditVipListArr)
        },

        //--------------------------------------------------------------------------------------------------------

        // 详情接口
        detailsInit () {
            let id =  this.$route.params.id
            VDetailAgent({
                agentNo: id
            }).then(({ code, data }) => {
                if (code === 200) {
                    let info = data.info
                    this.form.agentAccount = info.phone
                    this.form.agentName = info.name
                    this.form.code = info.code
                    this.form.agentGrade = info.levelName
                    this.level = info.levelName == '市级代理' ? 1 : 2
                    this.form.regionalDivision = info.areaDivisionProportion
                    this.form.bond = info.earnesMoney
                    this.form.shareProportion = info.divisionProportion
                    this.form.termOfValidity = [moment(info.startTime, this.dateFormat), moment(info.endTime, this.dateFormat)]
                    this.starTime = info.startTime
                    this.endTime = info.endTime
                    let ckArr = []
                    let vipArr = []
                    let sellingCkArr = []
                    let sellingVipArr = []

                    if(data.businessList){
                        for (let i = 0; i < data.businessList.length; i++){
                            if (data.businessList[i].businessType == 1) {
                                ckArr.push(data.businessList[i])
                            } else {
                                vipArr.push(data.businessList[i])
                            }
                        }
                    }

                    if(data.productList){
                        for (let j = 0; j < data.productList.length; j++){
                            if (data.productList[j].productType == 1) {
                                sellingCkArr.push(data.productList[j])
                            } else {
                                sellingVipArr.push(data.productList[j])
                            }
                        }
                    }

                    this.editListInit(ckArr,vipArr,sellingCkArr,sellingVipArr)
                }
            });
        },

        // 编辑初始化
        editListInit (ckArr, vipArr, sellingCkArr, sellingVipArr) {
            let curriculumRowKeysArrJson = []
            let vipRowKeysArrJson = []
            let sellingCurriculumRowKeysArrJson = []
            let sellingvipRowKeysArrJson = []

            for (let i = 0; i <this.curriculumDataSource.length; i++) {
                for (let j = 0; j < ckArr.length; j++) {
                    if (ckArr[j].enableFlag == 1 && ckArr[j].businessNo === this.curriculumDataSource[i].courseNo) {
                        curriculumRowKeysArrJson.push(this.curriculumDataSource[i]);
                        this.curriculumRowKeys.push(this.curriculumDataSource[i].courseNo);
                        this.curriculumDataSource[i].cost = ckArr[j].cost
                        this.curriculumDataSource[i].isTrue = true
                    }
                }
            }

            for (let i = 0; i <this.vipDataSource.length; i++) {
                for (let j = 0; j < vipArr.length; j++) {
                    if (vipArr[j].enableFlag == 1 && vipArr[j].businessNo === this.vipDataSource[i].memberNo) {
                        vipRowKeysArrJson.push(this.vipDataSource[i]);
                        this.vipRowKeys.push(this.vipDataSource[i].memberNo);
                        this.vipDataSource[i].cost = vipArr[j].cost
                        this.vipDataSource[i].isTrue = true
                    }
                }
            }

            for (let i = 0; i <this.sellingCourseData.length; i++) {
                for (let j = 0; j < sellingCkArr.length; j++) {
                    if (sellingCkArr[j].enableFlag == 1 && sellingCkArr[j].productId === this.sellingCourseData[i].productId) {
                        sellingCurriculumRowKeysArrJson.push(this.sellingCourseData[i]);
                        this.sellingCourseRowKeys.push(this.sellingCourseData[i].productId);
                        this.sellingCourseData[i].cost = sellingCkArr[j].cost
                        this.sellingCourseData[i].isTrue = true
                    }
                }
            }

            for (let i = 0; i <this.sellingVipData.length; i++) {
                for (let j = 0; j < sellingVipArr.length; j++) {
                    if (sellingVipArr[j].enableFlag == 1 && sellingVipArr[j].productId === this.sellingVipData[i].productId) {
                        sellingvipRowKeysArrJson.push(this.sellingVipData[i]);
                        this.sellingVipRowKeys.push(this.sellingVipData[i].productId);
                        this.sellingVipData[i].cost = sellingVipArr[j].cost
                        this.sellingVipData[i].isTrue = true
                    }
                }
            }

            this.jsonCurriculumRowKeys = JSON.stringify(curriculumRowKeysArrJson)
            this.jsonvipRowKeys = JSON.stringify(vipRowKeysArrJson)
            this.jsonsellingCurriculumRowKeysArrJson = JSON.stringify(sellingCurriculumRowKeysArrJson)
            this.jsonsellingvipRowKeysArrJson = JSON.stringify(sellingvipRowKeysArrJson)
        },

        // 新增初始化
        forInit () {
            let id = this.$route.params.id
            if (id == 0) {
                for (let i = 0; i < this.curriculumDataSource.length; i++) {
                    this.curriculumRowKeys.push(this.curriculumDataSource[i].courseNo)
                }
                for (let a = 0; a < this.vipDataSource.length; a++) {
                    this.vipRowKeys.push(this.vipDataSource[a].memberNo)
                }
                for (let s = 0; s < this.sellingCourseData.length; s++) {
                    this.sellingCourseRowKeys.push(this.sellingCourseData[s].productId)
                }
                for (let j = 0; j < this.sellingVipData.length; j++) {
                    this.sellingVipRowKeys.push(this.sellingVipData[j].productId)
                }
            }
        },

        // 获取所有会员默认成本
        vipListFn () {
            AllusermeBercost().then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.vipDataSource = data;
                    let id = this.$route.params.id
                    if (id == 0) {
                        this.vipDataSource.forEach((item, index) => {
                            item.isTrue = true
                        })
                    }
                }
            });
        },

        // 获取所有课程默认成本
        curriculumListFn () {
            AllcourSecode({
                name:this.culumval,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.curriculumDataSource = data;
                    let id = this.$route.params.id
                    if (id == 0) {
                        this.curriculumDataSource.forEach((item, index) => {
                            item.isTrue = true
                        })
                    }
                }
            });
        },

        // 获取所有直销课程默认成本
        sellingCourseFn(){
            PAllcoursecode({
                name:this.culumval,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.sellingCourseData = data;
                    let id = this.$route.params.id
                    if (id == 0) {
                        this.sellingCourseData.forEach((item, index) => {
                            item.isTrue = true
                        })
                    }
                }
            });
        },

        // 获取所有直销VIP默认成本
        sellingVipFn(){
            Allusermembercost({
                name:this.culumval,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.sellingVipData = data;
                    let id = this.$route.params.id
                    if (id == 0) {
                        this.sellingVipData.forEach((item, index) => {
                            item.isTrue = true
                        })
                    }
                }
            });
        },

        // 获取代理等级列表
        agentGrade () {
            AgentLevel().then(({ code, data }) => {
                if (code === 200) {
                    this.agentGradeList = data.slice(0, data.length - 1)
                }
            });
        },

        // 监听选择代理等级后
        agentGradeChange (val) {
            let arr = val.split(',')
            this.levelNo = arr[1]
            this.level = arr[0]*1
            this.form.city = undefined,
                this.province = ''
            this.city = ''
            this.area = ''
            AgentListbylevelno({
                level: this.level == 2 ? this.level -1 : this.level
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.municipaLevel = data
                }
            });
        },

        // 监听选择省市区
        cityChange (value, selectedOptions) {
            this.province = selectedOptions[0].label
            this.city = selectedOptions[1].label
            if (this.level != 1) {
                this.area = selectedOptions[2].label
            }
        },
    },
};
</script>

<style lang="less" scoped>
.boxEdit {
    min-height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        .header {
            width: 100%;
            display: flex;
            .aggregate {
                width: 50%;
                height: 48px;
                line-height: 48px;
                background-color: #fff3e6;
                position: relative;
                color: #ff8200;
                font-weight: 600;
                .jbxx {
                    width: 100%;
                    text-align: center;
                }
                .tjdl {
                    width: 100%;
                    text-align: center;
                }
                .sj1 {
                    position: absolute;
                    top: 0;
                    right: -43px;
                    width: 0;
                    height: 0;
                    line-height: 0;
                    font-size: 0;
                    border: 24px solid transparent;
                    border-left-color: #ff8200;
                    z-index: 100;
                }
                .sj2 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    line-height: 0;
                    font-size: 0;
                    border: 24px solid transparent;
                    border-left-color: #ff8200;
                }
            }
            .activeClass {
                background-color: #ff8200;
                color: #fff;
                .sj2 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    line-height: 0;
                    font-size: 0;
                    border: 24px solid transparent;
                    border-left-color: #fff;
                }
            }
        }
        .section {
            margin-top: 32px;
        }
        .csection {
            padding: 16px;
            .name {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                font-weight: 700;
                span {
                    color: #da3939;
                }
            }
            .searchStyle{
                display: flex;
                justify-content: space-between;
                .tab {
                    width: 100%;
                    display: flex;
                    margin: 16px 0;
                    .tabName {
                        width: 112px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background-color: #fff;
                        font-size: 15px;
                        color: #000;
                        border: 1px solid #f1f1f1;
                        cursor: pointer;
                    }
                    .tabName:nth-child(2) {
                        border-left: 0;
                    }
                    .tabStyle {
                        background-color: #f5f5f5;
                    }
                }
                .seach{
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
